import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import KuroLink from '../components/common/KuroLink'
import { CurrencyFormat } from '../components/common/CurrencyFormat'
import '../styles/home.css'
import moment from 'moment-timezone'
import admin from '../reducers/admin'
import { getInwardInvoices } from '../actions/admin'

moment.tz.setDefault('Asia/Kolkata')

const Home = ({ admin: { inwardinvoices }, getInwardInvoices, user: { token, userDetails } }) => {

    const access = userDetails.access;
    const [kgorderData, setkgorderData] = useState(null)
    const [ordersData, setOrdersData] = useState(null)
    const [filteredInvoices, setfilteredInvoices] = useState(null)
    const [salesdata, setsalesdata] = useState({
        "invoice_curmonth": 0,
        "invoice_curyear": 0,
        "invoice_lastmonth": 0,
        "invoice_lastyear": 0,
        "credit_curmonth": 0,
        "credit_curyear": 0,
        "credit_lastmonth": 0,
        "credit_lastyear": 0,
        "debit_curmonth": 0,
        "debit_curyear": 0,
        "debit_lastmonth": 0,
        "debit_lastyear": 0,
        "invoice_curquater": 0,
        "credit_curquater": 0,
        "debit_curquater": 0,
        "invoice_lastquater": 0,
        "credit_lastquater": 0,
        "debit_lastquater": 0
    })

    const [purchasedata, setpurchasedata] = useState({
        "invoice_curmonth": 0,
        "invoice_curyear": 0,
        "invoice_lastmonth": 0,
        "invoice_lastyear": 0,
        "credit_curmonth": 0,
        "credit_curyear": 0,
        "credit_lastmonth": 0,
        "credit_lastyear": 0,
        "debit_curmonth": 0,
        "debit_curyear": 0,
        "debit_lastmonth": 0,
        "debit_lastyear": 0,
        "invoice_curquater": 0,
        "credit_curquater": 0,
        "debit_curquater": 0,
        "invoice_lastquater": 0,
        "credit_lastquater": 0,
        "debit_lastquater": 0
    })

    const [paymentdata, setpaymentdata] = useState({
        "curmonth": 0,
        "curyear": 0,
        "lastmonth": 0,
        "lastyear": 0,
        "curquaterdata": 0,
        "lastquaterdata": 0
    })
    useEffect(() => {
        if (inwardinvoices !== null) {
            filterInvoices()
            // console.log(inwardinvoices)
        }
    }, [inwardinvoices])

    useEffect(() => {
        getInwardInvoices()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/sales', config).then(res => {
            let tempsales = { ...salesdata }
            tempsales = res.data[0]
            setsalesdata(tempsales)
        })

        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/purchases', config).then(res => {
            let temppurchases = { ...purchasedata }
            temppurchases = res.data[0]
            setpurchasedata(temppurchases)
        })

        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/outwardpayments', config).then(res => {
            let temppayments = { ...paymentdata }
            temppayments = res.data[0]
            setpaymentdata(temppayments)
        })

        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/kgorders', config).then(res => {
            setkgorderData(res.data)
        })

        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/tporders', config).then(res => {
            setOrdersData(res.data)
        })
    }, [])

    const filterInvoices = () => {
        let tempinvoices = [...inwardinvoices[0].invoices];
        tempinvoices = tempinvoices.filter(inv => inv.pay_status === "Payment Pending");

        let today = new Date();
        let dueDateThreshold = new Date(today);
        dueDateThreshold.setDate(today.getDate() + 5);

        tempinvoices = tempinvoices.filter(inv => {
            let dueDate = new Date(inv.due_date);
            return dueDate <= dueDateThreshold;
        });

        setfilteredInvoices(tempinvoices)
    }


    return (
        <div className="home mx-width txt-light-grey">
            {access === "Super" ? <>
                <h3>Kuro Cadence Admin Portal</h3>
                <div style={{ display: 'flex', flexWrap: 'wrap' }} className='col_2'>
                    {salesdata !== null &&
                        <>
                            <table className="border minimum_height even_odd">
                                <tbody>
                                    <tr>
                                        <th colSpan={2}>Sales</th>
                                    </tr>
                                    <tr>
                                        <td>Current Month</td>
                                        <td>{CurrencyFormat(salesdata.invoice_curmonth - salesdata.credit_curmonth + salesdata.debit_curmonth)}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Month</td>
                                        <td>{CurrencyFormat(salesdata.invoice_lastmonth - salesdata.credit_lastmonth + salesdata.debit_lastmonth)}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Year This Month</td>
                                        <td>{CurrencyFormat(salesdata.invoice_lycurmonth - salesdata.credit_lycurmonth + salesdata.debit_lycurmonth)}</td>
                                    </tr>
                                    <tr>
                                        <td>Current Quarter</td>
                                        <td>{CurrencyFormat(salesdata.invoice_curquater - salesdata.credit_curquater + salesdata.debit_curquater)}</td>
                                    </tr>  <tr>
                                        <td>Last Quarter</td>
                                        <td>{CurrencyFormat(salesdata.invoice_lastquater - salesdata.credit_lastquater + salesdata.debit_lastquater)}</td>
                                    </tr>
                                </tbody>
                                    <tr>
                                        <td>Current Year</td>
                                        <td>{CurrencyFormat(salesdata.invoice_curyear - salesdata.credit_curyear + salesdata.debit_curyear)}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Year</td>
                                        <td>{CurrencyFormat(salesdata.invoice_lastyear - salesdata.credit_lastyear + salesdata.debit_lastyear)}</td>
                                    </tr>
                            </table>
                        </>
                    }
                    {purchasedata !== null &&
                        <>
                            <table className="border even_odd minimum_height">
                                <tbody>
                                    <tr>
                                        <th colSpan={2}>Purchases</th>
                                    </tr>
                                    <tr>
                                        <td>Current Month</td>
                                        <td>{CurrencyFormat(purchasedata.invoice_curmonth - purchasedata.credit_curmonth + purchasedata.debit_curmonth)}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Month</td>
                                        <td>{CurrencyFormat(purchasedata.invoice_lastmonth - purchasedata.credit_lastmonth + purchasedata.debit_lastmonth)}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Year This Month</td>
                                        <td>{CurrencyFormat(purchasedata.invoice_lastmonth - purchasedata.credit_lastmonth + purchasedata.debit_lastmonth)}</td>
                                    </tr>
                                    <tr>
                                        <td>Current Quarter</td>
                                        <td>{CurrencyFormat(purchasedata.invoice_curquater - purchasedata.credit_curquater + purchasedata.debit_curquater)}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Quarter</td>
                                        <td>{CurrencyFormat(purchasedata.invoice_lastquater - purchasedata.credit_lastquater + purchasedata.debit_lastquater)}</td>
                                    </tr>
                                    <tr>
                                        <td>Current Year</td>
                                        <td>{CurrencyFormat(purchasedata.invoice_curyear - purchasedata.credit_curyear + purchasedata.debit_curyear)}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Year</td>
                                        <td>{CurrencyFormat(purchasedata.invoice_lastyear - purchasedata.credit_lastyear + purchasedata.debit_lastyear)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                    }
                    {paymentdata !== null &&
                        <>
                            <table className="border minimum_height even_odd">
                                <tbody>
                                    <tr>
                                        <th colSpan={2}>Payments</th>
                                    </tr>
                                    <tr>
                                        <td>Current Month</td>
                                        <td>{CurrencyFormat(paymentdata.curmonth)}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Month</td>
                                        <td>{CurrencyFormat(paymentdata.lastmonth)}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Year This Month</td>
                                        <td>{CurrencyFormat(paymentdata.lycurmonth)}</td>
                                    </tr>
                                    <tr>
                                        <td>Current Quarter</td>
                                        <td>{CurrencyFormat(paymentdata.curquaterdata)}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Quarter</td>
                                        <td>{CurrencyFormat(paymentdata.lastquaterdata)}</td>
                                    </tr>
                                    <tr>
                                        <td>Current Year</td>
                                        <td>{CurrencyFormat(paymentdata.curyear)}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Year</td>
                                        <td>{CurrencyFormat(paymentdata.lastyear)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                    }
                </div>
                <>
                    {filteredInvoices !== null && filteredInvoices.length > 0 && <table cellPadding="0" cellSpacing="0" className="border minimum_height even_odd">
                        <tr>
                            <th> This Week Pending Payments</th>
                            <td>{CurrencyFormat(filteredInvoices.reduce((a, b) => a + b.totalprice, 0))}</td>
                        </tr>
                    </table>}
                    {filteredInvoices !== null && filteredInvoices.length > 0 && <table cellPadding="0" cellSpacing="0" className="border even_odd">
                        <tr>
                            <th>Invoice Id</th>
                            <th>Vendor</th>
                            <th>Invoice Date</th>
                            <th>Invoice Total</th>
                            <th>Due Date</th>
                        </tr>
                        {filteredInvoices.map((invoice, i) => {
                            return <tbody>
                                <tr>
                                    <td>{invoice.invoiceid}</td>
                                    <td>{invoice.vendor}</td>
                                    <td>{invoice.invoice_date === "" ? "" : moment(invoice.invoice_date).format('DD-MM-YYYY')}</td>
                                    <td>{CurrencyFormat(invoice.totalprice)}</td>
                                    <td>{invoice.due_date === "" ? "" : moment(invoice.due_date).format('DD-MM-YYYY')}</td>
                                </tr>
                            </tbody>
                        })}
                    </table>}
                </>
            </> : <h3>K-Team Portal</h3>}


            <div className='box'>
                <div style={{ marginBottom: "50px" }} className="order-list txt-light-grey">
                    <h4>TP Orders</h4>
                    {ordersData !== null && ordersData.filter(order => (order.order_status === "Created") || (order.order_status === "Authorized") || (order.order_status === "Inventory Added")).length > 0 ? (
                        <div className="order">
                            <table cellPadding="0" cellSpacing="0" className="border home_mob">
                                <tbody>
                                    <tr>
                                        <th>Order Id</th>
                                        <th>Order Date</th>
                                        <th>Channel</th>
                                        <th>TP Order Id</th>
                                        <th>Customer Name</th>
                                        <th>Mobile</th>
                                        <th>Order Total</th>
                                        <th>Order Status</th>
                                        <th>Dispatch Due Date</th>
                                    </tr>
                                    {ordersData.map((order, i) =>
                                        ((order.order_status === "Created") || (order.order_status === "Authorized") || (order.order_status === "Inventory Added")) &&
                                        <tr key={i}>
                                            <td><KuroLink to={'tporders/' + order.orderid}>{order.orderid.toUpperCase()}</KuroLink></td>
                                            <td>{order.order_date === "" ? "" : moment(order.order_date).format('DD-MM-YYYY')}</td>
                                            <td>{order.channel}</td>
                                            <td>{order.tporderid}</td>
                                            <td>{order.user.name}</td>
                                            <td>{order.user.phone}</td>
                                            <td>{order.order_total}</td>
                                            <td>{order.order_status !== "Authorized" && order.order_status !== "Created" ? "Being Processed" : order.order_status}</td>
                                            <td style={{
                                                color: moment(order.dispatchby_date).isSameOrBefore(moment()) ? 'red' :
                                                    (moment(order.dispatchby_date).diff(moment(), 'days') <= 1 ? 'yellow' : 'inherit')
                                            }}>{order.dispatchby_date === "" ? "" : moment(order.dispatchby_date).format('DD-MM-YYYY')}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p className='txt-light'>No Order is Being Processed</p>
                    )}
                </div>
                <div className='offlinelineorder txt-light-grey'>
                    <h4>KG Orders</h4>
                    {kgorderData !== null && kgorderData.filter(order => (order.order_status === "Created") || (order.order_status === "Authorized") || (order.order_status === "Inventory Added")).length > 0 ? (
                        <div className="order">
                            <table cellPadding="0" cellSpacing="0" className="border">
                                <tbody>
                                    <tr>
                                        <th>Order Id</th>
                                        <th>Order Date</th>
                                        <th>Customer Name</th>
                                        <th>Mobile</th>
                                        <th>Order Total</th>
                                        <th>Amount Due</th>
                                        <th>Order Status</th>
                                        <th>Dispatch Due Date</th>
                                    </tr>
                                    {kgorderData.map((order, i) =>
                                        ((order.order_status === "Created") || (order.order_status === "Authorized") || (order.order_status === "Inventory Added")) &&
                                        <tr key={i}>
                                            <td><KuroLink to={'offlineorders/' + order.orderid}>{order.orderid.toUpperCase()}</KuroLink></td>
                                            <td>{order.order_date === "" ? "" : moment(order.order_date).format('DD-MM-YYYY')}</td>
                                            <td>{order.user.name}</td>
                                            <td>{order.user.phone}</td>
                                            <td>{order.totalprice}</td>
                                            <td>{order.amount_due}</td>
                                            <td>{order.order_status !== "Authorized" && order.order_status !== "Created" ? "Being Processed" : order.order_status}</td>
                                            <td style={{
                                                color: moment(order.dispatchby_date).isSameOrBefore(moment()) ? 'red' :
                                                    (moment(order.dispatchby_date).diff(moment(), 'days') <= 1 ? 'yellow' : 'inherit')
                                            }}>
                                                {order.dispatchby_date === "" ? "" : moment(order.dispatchby_date).format('DD-MM-YYYY')}
                                            </td>

                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p className='txt-light'>No Order is Being Processed</p>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getInwardInvoices })(Home)