import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import KuroLink from '../components/common/KuroLink'
import '../styles/table.css'
import '../styles/profile.css'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'

const Profile = ({ user: { token, userDetails } }) => {

    const [empdata, setempdata] = useState(null)
    const [editFlag, setEditFlag] = useState(false)
    const [OTPsent, setOTPsent] = useState(false)
    const [otp, setOtp] = useState("")
    const { userid } = useParams()

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        };

        let tempUserId = userid ? userid : userDetails.userid

        axios(process.env.REACT_APP_KC_API_URL + 'empprofile?userid=' + tempUserId, config)
            .then((res) => {
                let data = res.data
                Object.values(data[0]).forEach((value) => {
                    if (value === null) data[0][value] = ""
                })
                setempdata(data)
            });
    }, [])

    const updateHandler = (e, key, subkey) => {
        const newEmpData = [...empdata];

        if (key === 'user_details') {
            if (e.target.type === 'date') {
                newEmpData[0][key][subkey] = moment(e.target.value).format('YYYY-MM-DD');
            } else newEmpData[0][key][subkey] = e.target.value
        } else newEmpData[0][key] = e.target.value;

        setempdata(newEmpData);
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        };

        axios.post(process.env.REACT_APP_KC_API_URL + 'empprofile', empdata[0], config)
            .then((res) => {
                alert("Profile updated successfully");
                setEditFlag(false)
            })
            .catch((error) => {
                console.error("Error updating profile:", error);
            });
    };

    const checkEditAccess = () => {
        if (userDetails.accesslevel.profile === "edit") {
            return true
        } else {
            return empdata[0].user_details.edit
        }
    }

    const getOTP = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        const newuser = "false"
        const body = JSON.stringify({ newuser, "userid": empdata[0].phone })

        axios.post(process.env.REACT_APP_KC_API_URL + 'verify', body, config)
            .then((res) => {
                setOTPsent(true)
            })
    }

    const verifyOTP = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        const body = { "phone": empdata[0].phone, "otp": otp, "userid": userDetails.userid }
        axios.post(process.env.REACT_APP_KC_API_URL + 'empprofile?otp=true', body, config)
            .then((res) => {
                setempdata(res.data);
            })
            .catch((error) => {
                alert("Invalid OTP")
            });
    }

    const editUserOnly = () => {
        if (userDetails.accesslevel.profile === "edit") {
            return editFlag
        } else {
            return false
        }
    }

    return (
        <div className='profile mx-width txt-light'>
            <h2>Profile</h2>
            <table className='border' cellPadding="0" cellSpacing="0">
                {empdata !== null && empdata.map((emp, i) =>
                    <tbody key={i}>
                        <tr>
                            <td>Emp ID</td>
                            <td>{emp.userid}</td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.name} onChange={(e) => updateHandler(e, 'name', '')} />
                                ) : (
                                    <p>{emp.name}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Mobile No.</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.phone} onChange={(e) => updateHandler(e, 'phone', '')} />
                                ) : (
                                    <p>{emp.phone}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Email Address</td>
                            <td>
                                {editFlag ? (
                                    <input type="email" value={emp.email} onChange={(e) => updateHandler(e, 'email', '')} />
                                ) : (
                                    <p>{emp.email}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Username</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.username} onChange={(e) => updateHandler(e, 'username', '')} />
                                ) : (
                                    <p>{emp.username}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Phone Verified</td>
                            <td>
                                {emp.user_details.phone_verified ? (
                                    <p style={{ color: 'green', fontWeight: 'bold' }}>Verified</p>
                                ) : OTPsent ? (
                                    <div className='otp'>
                                        <input type="number" placeholder='Enter OTP' value={otp} onChange={(e) => setOtp(e.target.value)} />
                                        <button onClick={() => verifyOTP()}>Verify</button>
                                    </div>
                                ) : (
                                    <div className='otp'>
                                        <input type="phone" value={emp.phone ? emp.phone : ''} onChange={(e) => updateHandler(e, 'phone', '')} />
                                        <button onClick={() => getOTP()}>Get OTP</button>
                                    </div>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Gender</td>
                            <td>
                                {editFlag ? (
                                    <select value={emp.user_details.gender} onChange={(e) => updateHandler(e, 'user_details', 'gender')}>
                                        <option>Please Select Gender</option>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                    </select>
                                ) : (
                                    <p>{emp.user_details.gender === 'M' ? 'Male' : 'Female'}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Date of Birth</td>
                            <td>
                                {editFlag ? (
                                    <input type="date" value={emp.user_details.dob ? new Date(emp.user_details.dob).toISOString().split('T')[0] : ''} onChange={(e) => updateHandler(e, 'user_details', 'dob')} />
                                ) : (
                                    <p>{emp.user_details.dob ? new Date(emp.user_details.dob).toLocaleDateString() : ''}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Permanent Address</th>
                        </tr>
                        <tr>
                            <td>Line 1</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.permadd_line1} onChange={(e) => updateHandler(e, 'user_details', 'permadd_line1')} />
                                ) : (
                                    <p>{emp.user_details.permadd_line1}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Line 2</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.permadd_line2} onChange={(e) => updateHandler(e, 'user_details', 'permadd_line2')} />
                                ) : (
                                    <p>{emp.user_details.permadd_line2}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.permadd_city} onChange={(e) => updateHandler(e, 'user_details', 'permadd_city')} />
                                ) : (
                                    <p>{emp.user_details.permadd_city}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.permadd_state} onChange={(e) => updateHandler(e, 'user_details', 'permadd_state')} />
                                ) : (
                                    <p>{emp.user_details.permadd_state}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Pin code</td>
                            <td>
                                {editFlag ? (
                                    <input type="number" value={emp.user_details.permadd_pincode} onChange={(e) => updateHandler(e, 'user_details', 'permadd_pincode')} />
                                ) : (
                                    <p>{emp.user_details.permadd_pincode}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Present Address</th>
                        </tr>
                        <tr>
                            <td>Line 1</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.presadd_line1} onChange={(e) => updateHandler(e, 'user_details', 'presadd_line1')} />
                                ) : (
                                    <p>{emp.user_details.presadd_line1}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Line 2</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.presadd_line2} onChange={(e) => updateHandler(e, 'user_details', 'presadd_line2')} />
                                ) : (
                                    <p>{emp.user_details.presadd_line2}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.presadd_city} onChange={(e) => updateHandler(e, 'user_details', 'presadd_city')} />
                                ) : (
                                    <p>{emp.user_details.presadd_city}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.presadd_state} onChange={(e) => updateHandler(e, 'user_details', 'presadd_state')} />
                                ) : (
                                    <p>{emp.user_details.presadd_state}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Pin code</td>
                            <td>
                                {editFlag ? (
                                    <input type="number" value={emp.user_details.presadd_pincode} onChange={(e) => updateHandler(e, 'user_details', 'presadd_pincode')} />
                                ) : (
                                    <p>{emp.user_details.presadd_pincode}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Bank Account Details</th>
                        </tr>
                        <tr>
                            <td>Bank Account No</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.bank_accountno} onChange={(e) => updateHandler(e, 'user_details', 'bank_accountno')} />
                                ) : (
                                    <p>{emp.user_details.bank_accountno}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Bank Name</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.bank_name} onChange={(e) => updateHandler(e, 'user_details', 'bank_name')} />
                                ) : (
                                    <p>{emp.user_details.bank_name}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Bank Branch</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.bank_branch} onChange={(e) => updateHandler(e, 'user_details', 'bank_branch')} />
                                ) : (
                                    <p>{emp.user_details.bank_branch}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Bank IFSC</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.bank_ifsc} onChange={(e) => updateHandler(e, 'user_details', 'bank_ifsc')} />
                                ) : (
                                    <p>{emp.user_details.bank_ifsc}</p>
                                )}
                            </td>
                        </tr>
                        {userDetails.accesslevel.profile === "edit" && <>
                            <tr>
                                <th colSpan={2}>BFC Details</th>
                            </tr>
                            <tr>
                                <td>BFC Account No</td>
                                <td>
                                    {editFlag ? (
                                        <input type="text" value={emp.user_details.bfc_accountno} onChange={(e) => updateHandler(e, 'user_details', 'bfc_accountno')} />
                                    ) : (
                                        <p>{emp.user_details.bfc_accountno}</p>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>BFC Name</td>
                                <td>
                                    {editFlag ? (
                                        <input type="text" value={emp.user_details.bfc_name} onChange={(e) => updateHandler(e, 'user_details', 'bfc_name')} />
                                    ) : (
                                        <p>{emp.user_details.bfc_name}</p>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>BFC Branch</td>
                                <td>
                                    {editFlag ? (
                                        <input type="text" value={emp.user_details.bfc_branch} onChange={(e) => updateHandler(e, 'user_details', 'bfc_branch')} />
                                    ) : (
                                        <p>{emp.user_details.bfc_branch}</p>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>BFC IFSC</td>
                                <td>
                                    {editFlag ? (
                                        <input type="text" value={emp.user_details.bfc_ifsc} onChange={(e) => updateHandler(e, 'user_details', 'bfc_ifsc')} />
                                    ) : (
                                        <p>{emp.user_details.bfc_ifsc}</p>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>BFC Code</td>
                                <td>
                                    {editFlag ? (
                                        <input type="text" value={emp.user_details.bfc_code} onChange={(e) => updateHandler(e, 'user_details', 'bfc_code')} />
                                    ) : (
                                        <p>{emp.user_details.bfc_code}</p>
                                    )}
                                </td>
                            </tr>
                        </>}
                        <tr>
                            <td>Emergency Contact Name</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.emerg_name} onChange={(e) => updateHandler(e, 'user_details', 'emerg_name')} />
                                ) : (
                                    <p>{emp.user_details.emerg_name}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Emergency Contact Number</td>
                            <td>
                                {editFlag ? (
                                    <input type="number" value={emp.user_details.emerg_phone} onChange={(e) => updateHandler(e, 'user_details', 'emerg_phone')} />
                                ) : (
                                    <p>{emp.user_details.emerg_phone}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>ID Proof Type</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.idproof_type} onChange={(e) => updateHandler(e, 'user_details', 'idproof_type')} />
                                ) : (
                                    <p>{emp.user_details.idproof_type}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>ID Proof Number</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.idproof_number} onChange={(e) => updateHandler(e, 'user_details', 'idproof_number')} />
                                ) : (
                                    <p>{emp.user_details.idproof_number}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>PAN No</td>
                            <td>
                                {editFlag ? (
                                    <input type="text" value={emp.user_details.pan_no} onChange={(e) => updateHandler(e, 'user_details', 'pan_no')} />
                                ) : (
                                    <p>{emp.user_details.pan_no}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Paid Offs</td>
                            <td>{editUserOnly() ? (
                                <input type="number" value={emp.user_details.paid_offs} onChange={(e) => updateHandler(e, 'user_details', 'paid_offs')} />
                            ) : (
                                <p>{emp.user_details.paid_offs}</p>
                            )
                            }</td>
                        </tr>
                        <tr>
                            <td>Available Offs</td>
                            <td>
                                {editUserOnly() ? (
                                    <input type="number" value={emp.user_details.available_offs} onChange={(e) => updateHandler(e, 'user_details', 'available_offs')} />
                                ) : (
                                    <p>{emp.user_details.available_offs}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Salary</td>
                            <td>
                                {editUserOnly() ? (
                                    <input type="number" value={emp.user_details.salary} onChange={(e) => updateHandler(e, 'user_details', 'salary')} />
                                ) : (
                                    <p>{emp.user_details.salary}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Role</td>
                            <td>
                                {editUserOnly() ? (
                                    <input type="text" value={emp.user_details.role} onChange={(e) => updateHandler(e, 'user_details', 'role')} />
                                ) : (
                                    <p>{emp.user_details.role}</p>
                                )
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Joining Date</td>
                            <td>
                                {editUserOnly() ? (
                                    <input type="text" value={emp.user_details.joining_date ? new Date(emp.user_details.joining_date).toISOString().split('T')[0] : ''} onChange={(e) => updateHandler(e, 'user_details', 'joining_date')} className="date" />
                                ) : (
                                    <p>{emp.user_details.joining_date}</p>
                                )}
                            </td>
                        </tr>
                    </tbody>
                )}
            </table>


            <p>&nbsp;</p>
            <div className='btn'>
                <KuroLink to='pwd-reset'><button>Change Password</button></KuroLink>
            </div>
            {!editFlag && checkEditAccess() && <div className='btn'>
                <button onClick={() => setEditFlag(true)}>Edit</button>
            </div>}
            {editFlag && <div className='btn'>
                <button onClick={() => submitHandler()}>Submit</button>
            </div>}
            <p>&nbsp;</p>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(Profile)