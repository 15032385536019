import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import KuroLink from '../components/common/KuroLink'
import '../styles/table.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const StockProd = ({ match, user: { token, userDetails }, products: { compData, accessData } }) => {

    const { params: { prodColl, prodType } } = match
    const [prods, setProds] = useState(null)
    const [proddata, setproddata] = useState(null)
    const history = useHistory();
    useEffect(() => {
        if (prodColl === "components") {
            setproddata(compData)
        } else if (prodColl === "accessories") {
            setproddata(accessData)
        }
    }, [prodColl, compData, accessData])

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        }
        let url = process.env.REACT_APP_KC_API_URL + 'kurostaff/inventory?collection=' + prodColl + '&type=' + prodType
        axios(url, config).then(res => {
            setProds(res.data)
        }).catch((err) => {
            if (err.response.status == 401) {
                history.push("/unauthorized")
            }
        })
    }, [prodColl, prodType])

    const categoryHandler = (category) => {
        switch (category) {
            case "mouse":
                return "/a/"
            case "mouse-accessories":
                return "/a/"
            case "keyboard":
                return "/a/"
            case "keyboard-accessories":
                return "/a/"
            case "kbdmouse":
                return "/a/"
            case "streaming-gear":
                return "/a/"
            default:
                return "/p/"
        }
    }

    return (
        <div className="core-comp mx-width txt-light-grey">
            <p className="breadcrumb txt-light-grey"><KuroLink to="/stock-register">Stock Register</KuroLink> &gt; {prodType}</p>
            <table className="border" cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        {userDetails.access === "Super" && <th>Product ID</th>}
                        <th>Title</th>
                        <th>Serial No.</th>
                        <th>Product No.</th>
                        <th>UPC No.</th>
                        <th>EAN No.</th>
                        <th>Min Price</th>
                        <th>Avg Price</th>
                        <th>Max Price</th>
                        <th>Quantity</th>
                    </tr>
                    {prods !== null && prods.map((prod) =>
                        <tr>
                            {userDetails.access == "Super" && proddata !== null && <td>{prod.productid}</td>}
                            <td>{proddata !== null && <a target="_blank" rel="noreferrer" href={"https://kurogaming.com" + categoryHandler(prodType) + proddata.filter(item => item.productid === prod.productid)[0]["prod_url"]}>{proddata !== null && proddata.filter(item => item.productid === prod.productid)[0]["title"]}</a>}</td>
                            <td>{prod.sr_no}</td>
                            <td>{prod.prod_no}</td>
                            <td>{prod.upc_no}</td>
                            <td>{prod.ean_no}</td>
                            <td>{prod.minprice}</td>
                            <td>{prod.avgprice}</td>
                            <td>{prod.maxprice}</td>
                            <td>{prod.quantity}</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <p>&nbsp;</p>
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    user: state.user
})

export default connect(mapStateToProps)(StockProd)